
%no-repeat-center {
    background-repeat: no-repeat;
    background-position: center;
}

.background-cover {
	@extend %no-repeat-center;
    background-size: cover;
}


.background-contain {
	@extend %no-repeat-center;
    background-size: contain;
}

