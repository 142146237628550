@include media-large {

    #left, #right {
        width: 50%;
    }

    body.hero-detail-open .hero-background {
        width: 200%;
    }

    .hero-detail {
    	right: -100%;
        width: 100%;
    }

}
