@include media-x-large {

	#left {
	    width: 66.66667%;
	}
	#right {
	    width: 33.33333%;
	}

}
