$hero-main-height: 500px;
$hero-detail-open-hero-main-height: 300px;
$hero-detail-height: 250px;

$right-column-height: 780px;


@include media-medium {

    #content {
        height: auto;
    }

    #left, #right {
        float: none;
        width: 100%;
    }

    #left {
        height: auto;
    }

    #right {
        height: $right-column-height;
        @include transform(none);
    }

    .hero-background {
        height: $hero-main-height;
    }

    .hero-main, .hero-detail {
        float: none;
    }

    .hero-detail {
        position: absolute;
        top: auto;
        top: initial;
        right: auto;
        right: initial;
        height: $hero-detail-height;
        width: 100%;
    }

    .hero-main {
        height: $hero-main-height;
    }

    body.hero-detail-open {

        .hero-background {
            width: 100%;
            height: $hero-detail-open-hero-main-height + $hero-detail-height;
        }

        .hero-main {
            height: $hero-detail-open-hero-main-height;
        }

        #right {
            margin-top: $hero-detail-height;
            @include transform(none);
        }
    }

}
