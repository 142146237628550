@import "cemex-css/variables.scss";
@import "cemex-css/mixins/media-queries.mixin.scss";

@import "utils/box.scss";
@import "utils/background.scss";

@import "bourbon";

$wrapper-background-color: #FFF;/*Changed by Base22*/
$content-min-height: 500px;

$hero-more-transition-time: 0.4s;
$hero-background-transition-factor: 1.2;

body {
    display: none;
}

#page-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #FFF;/*Changed by Base22*/
    z-index: 1000;
}

#main-content,
#grid-wrapper,
.portlet-column,
.portlet-dropzone,
.portlet-boundary,
.portlet,
.portlet-content,
.portlet-content-container,
.portlet-body,
.journal-content-article {
    height: 100%;
}

.b-full-row .portlet-column,
.b-full-row .portlet-dropzone,
.b-full-row .portlet-boundary,
.b-full-row .portlet,
.b-full-row .portlet-content,
.b-full-row .portlet-content-container,
.b-full-row .portlet-body,
.b-full-row .journal-content-article {
    height: initial;
}

#wrapper {
    background-color: $wrapper-background-color;
}

.has-control-menu {
   #wrapper {
        max-height: calc(100% - #{$liferay-top-control-menu-height});
    }
}

#mobile-navigation {
    opacity: 0;
}

#content-footer-wrapper {
    height: calc(100% - #{$theme-header-height});
}

#content {
    height: calc(80% - #{$theme-footer-height});
    min-height: $content-min-height;
}

.portlet > .portlet-content-editable {
    border-width: 0;

    &:hover {
        border-width: 1px;
    }
}


#left {
    .tile-content {
        overflow: hidden;
    }
}

#right {
    &, [class*=col-] {
        padding: 0;
    }
}

#left,
#right,
.hero-background,
.hero-main,
.hero-detail {
    height: 100%;
    @include transition(all $hero-more-transition-time);

    .no-transition & {
        @include transition(none !important);
    }

}

.hero-background {
    position: absolute;
    width: 100%;
    height: 100%;
    @include transition(all $hero-more-transition-time * $hero-background-transition-factor);
}

.hero-main, .hero-detail {
    float: left;
}

.hero-main {
    width: 100%;
    position: relative;
}

.hero-detail {
    position: absolute;
    top: 0;
    right: -50%;
    width: 50%;
}

body.hero-detail-open {

    .hero-background {
        width: 150%;
        @include transition(all $hero-more-transition-time / $hero-background-transition-factor);
    }

    #left {
        .tile-content {
            overflow: visible;
            overflow: initial;
        }
    }

    #right {
        @include transform(translateX(100%));
    }

}





body.fast {
    .hero-background {

        @include transition(all 0s $hero-more-transition-time);

        .hero-detail-open & {
            @include transition(none);
        }
    }
}

#cwrap {

    & {
        @include transition(all 0.4s);
    }

    &.choose {
        @include transform(translate(-200%, 0));
    }

    #country-hidden {
        width: 200%;
        height: 200%;
        position: absolute;
        left: 100%;
        top: 0;
    }

}


.feature-tile-title {
    position: absolute;
    width: 100%;
    text-align: right;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-top: 20px;
    line-height: 120%;

    &.gradient {
        @include linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0), $fallback: transparent);
    }
}

.tile {

    .cursor-pointer {
        cursor: pointer;
    }

    .tile-content {

        @extend .full-height;
        position: relative;
        overflow: hidden;

        > .background {
            @extend .background-cover;
            @extend .full-height;
            position: absolute;
            width: 100%;
        }

    }

    @mixin border-top($name, $color) {
        .border-top-#{$name} {
            border: solid;
            border-color: $color;
            border-width: 6px 0 0 0;
        }
    }

    @include border-top('red', #96182d);
    @include border-top('lightblue', #3FA9F5);

}
.cemex-wrapper #header {
    top: -65px;
}
