// WARNING: changes here must be reflected to main.js media query listeners
@mixin media-xx-large {
    @media (min-width: 1600px) {
        @content;
    }
}

@mixin media-x-large {
    @media (max-width: 1599px) {
        @content;
    }
}

@mixin media-large {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin media-medium {
    @media (max-width: 1023px) {
        @content;
    }
}

@mixin media-small {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin media-x-small {
    @media (max-width: 479px) {
        @content;
    }
}